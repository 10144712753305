<template>
  <div class="p-6">
    <DialogTitle bg-color="bg-victoria" label="Create Mock Test" align="center" />
    <form @submit.prevent="onSubmit">
      <div>
        <label class="text-uppercase font-bold text-xl mb-2 block mt-6">Choose Mock Type</label>
        <RadioButtonComponent
          active-class="font-bold"
          label-class="text-lg text-uppercase"
          return-type="object"
          @getObject="onChangeComponent"
          :items="sections"

        />
      </div>
      <div class="md-layout md-gutter my-4">
        <div class="md-layout-item md-size-35">
          <InputFieldComponent
            label="Mock Name"
            label-class="text-xl"
            placeholder="Summer Mock Test"
            v-model.trim="$v.form.practiceTestId.$model"
            :message="!$v.form.practiceTestId.required && $v.form.practiceTestId.$dirty ? 'Field is required' : null"
          />
        </div>
      </div>
      <div class="md-layout md-gutter mb-8">
        <div class="md-layout-item md-size-30">
          <label class="text-uppercase font-bold text-xl mb-2 block mt-6">
            Choose Create Type
          </label>
          <RadioButtonComponent
            active-class="font-bold"
            label-class="text-lg text-uppercase"
            :items="[{id: 1, label: 'Random'}, {id:2, label: 'Manual'}]"
            v-model.trim="$v.form.createType.$model"
            :message="!$v.form.createType.required && $v.form.createType.$dirty ? 'Field is required' : null"
          />
        </div>
        <div class="md-layout-item md-size-100">
          <components :is="component" ></components>
        </div>
      </div>

      <AccordionComponent />
        <div class="md-layout md-gutter">
            <div class="md-layout-item md-size-40">
                <TextAreaComponent
                    label="Short Description"
                    v-model.trim="$v.form.description.$model"
                    :message="!$v.form.description.required && $v.form.description.$dirty ? 'Field is required' : null"
                />
            </div>
            
            <div class="md-layout-item md-size-30">
                <TextAreaComponent
                    label="Starting Message"
                    v-model.trim="$v.form.startingMessage.$model"
                    :message="!$v.form.startingMessage.required && $v.form.startingMessage.$dirty ? 'Field is required' : null"
                />
            </div>
            <div class="md-layout-item md-size-30">
                <TextAreaComponent
                    label="End Message"
                    v-model.trim="$v.form.endMessage.$model"
                    :message="!$v.form.endMessage.required && $v.form.endMessage.$dirty ? 'Field is required' : null"
                />
            </div>

        </div>
      
      <div class="flex justify-end mt-4">
        <md-button class="text-uppercase">Save as draft</md-button>
        <md-button type="submit" class="text-uppercase mr-0 bg-victoria text-white rounded">Publish</md-button>
      </div>
    </form>
  </div>
</template>

<script>
import { 
  DialogTitle,
  InputFieldComponent,
  TextAreaComponent,
  RadioButtonComponent
  } from "@/components"
import AccordionComponent from "@/components/molecule/AccordionComponent";
import QuizOrDiagonasticMock from "@/components/molecule/mock-exam/item/QuizOrDiagonasticMock"
import SectionWiseMock from "@/components/molecule/mock-exam/item/SectionWiseMock"
import { required } from "vuelidate/lib/validators";
import { mapMutations } from "vuex";
export default {
  components: {
    QuizOrDiagonasticMock,
    SectionWiseMock,
    RadioButtonComponent,
    AccordionComponent,
    InputFieldComponent,
    TextAreaComponent,
    DialogTitle
  },
  data() {
    return {
      component: "",
      sections: [
        {id:1, label: 'Full Mock', component: ""},
        {id:2, label: 'Section Wise Mock', component: "SectionWiseMock"},
        {id:3, label: 'Partial / Mini Mock', component: ""},
        {id:4, label: 'Quiz / Diagonastic Mock', component: "QuizOrDiagonasticMock"}
      ],
      form: {
        practiceTestId: "",
        createType: "",
        description: "",
        startingMessage: "",
        endMessage: "",
      }
    };
  },
  methods: {
    ...mapMutations({
      dialog: "setShowDialog",
      setShowSnackbar: "setShowSnackbar"
    }),
    onSubmit() {
      this.$v.$touch();
      if(this.$v.$invalid) {
        return;
      }
      this.setShowSnackbar('Test creation saved successfully !');
      setTimeout(() => {
       this.dialog(false);
      }, 1000);
    },
    onChangeComponent({component}) {
      this.component = component;
    }
  },
  validations: {
    form: {
      practiceTestId: {required},
      createType: {required},
      description: {required},
      startingMessage: {required},
      endMessage: {required},
    }
  }
};
</script>