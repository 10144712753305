<template>
  <div>
    <label class="text-uppercase font-bold text-xl mb-2 block mt-6">Choose Section</label>
    <RadioButtonComponent
        active-class="font-bold"
        label-class="text-lg text-uppercase"
        return-type="object"
        :items="sections"
    />
  </div>
</template>

<script>
import { RadioButtonComponent } from "@/components";
import cms from "@/data/cms";
export default {
    
    computed: {
        sections() {
            return cms.sections.map(item => {
                return {
                    id: item.id,
                    label: item.name
                }
            })
        }
    },
    components: {
        RadioButtonComponent
    }
}
</script>

<style>

</style>