<template>
  <div>
    <label class="text-uppercase font-bold text-xl mb-2 block mt-6">Choose Section</label>
    <RadioButtonComponent
        active-class="font-bold"
        label-class="text-lg text-uppercase"
        return-type="object"
        :items="sections"
    />
    <div>
      <RadioButtonComponent
        class="inline-flex"
        label="SET TIMING"
        top-label-class="text-xl"
        active-class="font-bold"
        label-class="text-lg text-uppercase"
        return-type="object"
        :items="[{id: 1, label: 'Global'}, {id: 2, label: 'Custom'}]"
      />
      <InputFieldComponent
        placeholder="mm"
        class="w-16 inline-flex mx-1"
      />
        
      <InputFieldComponent
        placeholder="ss"
        class="w-16 inline-flex mx-1"
      />
      
      <InputFieldComponent
        placeholder="Quantity"
        class="w-24 inline-flex mx-1"
      />

    </div>
  </div>
</template>

<script>
import { RadioButtonComponent, InputFieldComponent } from "@/components";
import cms from "@/data/cms";
export default {
    
    computed: {
        sections() {
            return cms.sections.map(item => {
                return {
                    id: item.id,
                    label: item.name
                }
            })
        }
    },
    components: {
      InputFieldComponent,
      RadioButtonComponent
    }
}
</script>

<style>

</style>