<template>
  <div class="p-6">
        <DialogTitle bg-color="bg-victoria" label="Population of Canada" align="center" />
        <Content class="my-3" label="Test ID" text="T1205" />
        <div class="md-layout md-gutter">
            <div class="md-layout-item md-xsmall-size-100 md-large-size-30">
                <Content class="my-3" label="Test Type" text="Full Mock Test" />
            </div>
            <div class="md-layout-item md-xsmall-size-100 md-large-size-70">
                <Content class="my-3" label="Create Type" text="Manual" />
            </div>
        </div>
        
        <div class="md-layout md-gutter">
            <div class="md-layout-item md-xsmall-size-100 md-large-size-30">
                <Content class="my-3" label="Exam Date" text="12 Nov 2019" />
            </div>
            <div class="md-layout-item md-xsmall-size-100 md-large-size-70">
                <Content class="my-3" label="Created By" text="Kazi Shahin" />
            </div>
            <div class="md-layout-item md-xsmall-size-100 md-large-size-70">
                <Content class="my-3" label="Number of Questions" text="20" />
            </div>
            <div class="md-layout-item md-xsmall-size-100 md-large-size-70">
                <Content class="my-3" label="Total Time" text="18m 30s" />
            </div>
        </div>
        <Content class="my-3" label="Short Description" text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit perferendis eius aspernatur repellat facilis est." />
        <Content class="my-3" label="Starting Message" text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit perferendis eius aspernatur repellat facilis est. Velit perferendis eius aspernatur repellat facilis est." />

        <div>
            <div
                class="outline-gray-500 p-2 py-3 rounded flex justify-between pointer"
                @click="active = !active"
            >
               <h2> Question</h2>
               <md-icon class="m-0">{{ !active ? 'add': 'remove'}}</md-icon>
            </div>
            <div v-if="active">
                <template v-for="i in 10">
                    <div 
                        class="outline-gray-300 p-2 py-3 my-2 rounded flex justify-between pointer question-item"    
                        :key="i"
                    >
                    <div class="flex">
                        <span class="mr-4">RMCQS 1</span>
                        <h2 class="text-uppercase text-base">Population Of Bangladesh</h2>
                    </div>
                    <md-icon class="m-0 visibility-icon">visibility</md-icon>
                    </div>
                </template>
            </div>
        </div>
        <Content class="my-3" label="End Message" text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit perferendis eius aspernatur repellat facilis est." />
        <div class="md-layout md-gutter">
            <div class="md-layout-item md-xsmall-size-100 md-medium-size-30">
                <Content class="my-3" label="Status" text="In-active" />
            </div>
            <div class="md-layout-item md-xsmall-size-100 md-medium-size-70">
                <Content class="my-3" label="Created At" text="24 Dec, 2019, 12:36am" />
            </div>
        </div>
        <div class="flex justify-center my-6">
            <md-button class="bg-default text-uppercase rounded" @click="dialog(false)">Back</md-button>
            <md-button class="bg-victoria text-white text-uppercase rounded">Go to edit page</md-button>
        </div>
  </div>
</template>

<script>
import { DialogTitle } from "@/components";
import Content from "@/components/atom/Content";
import { mapMutations } from 'vuex';
export default {
    components: {
        DialogTitle,
        Content
    },
    data() {
        return {
            active: true
        }
    },
    methods: {
    ...mapMutations({
        dialog: "setShowDialog",
    }),
    }
}
</script>

<style lang="scss">

.question-item {
    .visibility-icon {
        visibility: hidden;
    }
}
.question-item:hover {
    transition: .5s;
    background-color: #daecec;
    .visibility-icon {
        visibility: visible;
    }
    
}
</style>